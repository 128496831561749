
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Auth, Hub, Amplify } from 'aws-amplify';
import { Spinner } from 'react-bootstrap';

// Toasts
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './itp.css';

// Dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { SignUpInReset, SignOut } from '../../comps/Amplify/Auth'; 
import Btn from '../../comps/Btn';

import utils from '../../core/utils';
import itp from '../../core/itp';

import Dashboard from "./Dashboard";

import useMe from '../../hooks/useMe';
import useHub from '../../hooks/useHub';

import ItpLayout from "./ItpLayout";
import { Products, Product } from "./Products";
import { Chemicals, Chemical } from "./Chemicals";
import { Users, User } from "./Users";
import { Labels, Label } from "./Labels";
import { Fabrics, Fabric } from "./Fabrics";
import { Hazards, Hazard } from "./Hazards";
import { Contents, Content } from "./Content";
import { Tags, Tag } from "./Tags";
import { Platform } from "./Platform";
import { WhiteBlackList } from "./WhiteBlackList";

dayjs.extend(relativeTime);

let client = {};
let clientId = itp.getStoredClientId();
let clientRef;
// clientRef = 'itp';
clientRef = process.env.REACT_APP_CLIENT_REF || 'itp';
// clientRef = 'respirex';
client = clientRef ? itp.clients[clientRef] : itp.clientsById[clientId];

// console.log('client', process.env);

Amplify.configure({
  Auth: itp.getAmplifyAuthConfig(clientRef),
  API: itp.getAmplifyApiConfig(),
});



const signUpOpts = {
    fields: utils.getFields(client.signUpFields),
};

const ClientPicker = () => {
    if(!utils.isLocalhost()) {
        return null;
    }

    const setClientId = (clientId) => {
        itp.setStoredClientId(clientId);
        window.location.reload();
    }

    const currentClientId = itp.getStoredClientId();

    return (
        <div align="center">
            {Object.keys(itp.clientsById).map((clientId, i)=>{
                const client = itp.clientsById[clientId];
                return (<span key={i}>
                    <span onClick={()=>{ setClientId(clientId); }} style={currentClientId===clientId?{color:'blue', cursor:'pointer'}:{cursor:'pointer'}}>{client.name}</span>
                    {' | '}
                </span>);
            })}
        </div>
    );
}

export default function ItpController() {

    const hub = useHub();
    const navigate = useNavigate();
    const { getMe, resetMe, isAdmin, isManager } = useMe();

    const [working, setWorking] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const init = async() => {
        
        utils.setWindowTitle(client.windowTitle);
        setWorking(true);
        // console.log('root.init');

        // Hub.dispatch(itp.events.CHANNEL, {event:itp.events.types.INIT, message:'init'});
        hub.dispatch(itp.events.CHANNEL, itp.events.types.INIT);

        let auth;
        try {
            auth = await Auth.currentAuthenticatedUser();
        }
        catch(error) {

        }

        if(auth) {
            let me;
            try {
                me = await getMe();
            }
            catch(error) {

            }

            if(me) {
                // Hub.dispatch(itp.events.CHANNEL, {event:itp.events.types.SIGN_IN, message:'signin'});
                hub.dispatch(itp.events.CHANNEL, itp.events.types.SIGN_IN);
                setLoggedIn(true);
            }
        }

        setWorking(false);
    }

    const handleHubEvent = async() => {
        // console.log('handleHubEvent', hub.channel, hub.event);
        // const { event } = e?.payload;
        // console.log('[HubEvent]', {e, event});

        if(hub.channel === 'auth') {
            switch(hub.event) {
            case 'signIn':
                await init();
                setLoggedIn(true);
                navigate(itp.getRouterPath());
                hub.dispatch(itp.events.CHANNEL, itp.events.types.SIGN_IN);
            break;
            case 'signOut':
                setLoggedIn(false);
                navigate(itp.getRouterPath());
                hub.dispatch(itp.events.CHANNEL, itp.events.types.SIGN_OUT);
            break;
            default:
                
            break;
            }
        }
    }

    useEffect(()=>{
        // const listener = Hub.listen(/.*/, handleHubEvent);

        init();
        
        // return listener;
    }, []);

    useEffect(()=>{ handleHubEvent(); }, [hub.event, hub.channel]);

    if(working) {
        return (<div align="center"><br /><Spinner /> Loading...</div>);
    }

    if(!loggedIn) {
        return (
            <>
                <ToastContainer />
                <Routes>
                    <Route path={itp.getRouterPath()} element={<ItpLayout />}>
                        <Route index element={<><h3 align="center">{client.appTitle}</h3><SignUpInReset signUp={{show:false}} /></>} />
                    </Route>
                    <Route path={'/'} element={<ItpLayout />}>
                        <Route path="signout" element={<SignOut />} />
                        <Route path="" element={<><h3 align="center">{client.appTitle}</h3><SignUpInReset signIn={{show:false}} signUp={signUpOpts} /></>} />
                    </Route>
                </Routes>
            </>
        );
    }

    if(!isAdmin() && !isManager()) {
        return (
            <>
                <ToastContainer />
                <h3 align="center">Ooops</h3>
                <hr />
                <br /><br />
                <p align="center">You do not have permission to use this tool<br /> <Btn variant="secondary" onClick={async()=>{ await Auth.signOut(); }} text="sign out" /></p>
                <br /><br />
            </>
        );
    }

    return (
        <>
            <ToastContainer />
            <Routes>
                <Route path={itp.getRouterPath()} element={<ItpLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="products/:productId" element={<Product />} />
                    <Route path="products" element={<Products />} />
                    <Route path="users/:userId" element={<User />} />
                    <Route path="users" element={<Users />} />
                    <Route path="content/:contentId" element={<Content />} />
                    <Route path="content" element={<Contents />} />
                    <Route path="lists" element={<WhiteBlackList />} />
                    {isAdmin() ? (
                        <>
                        <Route path="chemicals/:chemicalId" element={<Chemical />} />
                        <Route path="chemicals" element={<Chemicals />} />
                        <Route path="fabrics/:fabricId" element={<Fabric />} />
                        <Route path="fabrics" element={<Fabrics />} />
                        <Route path="labels/:labelId" element={<Label />} />
                        <Route path="labels" element={<Labels />} />
                        <Route path="hazards/:hazardId" element={<Hazard />} />
                        <Route path="hazards" element={<Hazards />} />
                        <Route path="tags/:tagId" element={<Tag />} />
                        <Route path="tags" element={<Tags />} />
                        <Route path="platform" element={<Platform />} />
                        </>
                    ) : null }
                    <Route path="signout" element={<SignOut />} />
                </Route>
                <Route path="/" element={<ItpLayout />}>
                    <Route path="signout" element={<SignOut />} />
                </Route>
            </Routes>
        </>
    )

}