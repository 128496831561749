import { Auth } from 'aws-amplify';
import utils from './utils';

const getRouterPath = (path) => {
    const basePath = '/cms';
    return path ? `${basePath}${path}` : basePath;
}
const settings = {
    brandName: 'PermaSURE CMS',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
}

const countryCodesObj = {
    "AF": "Afghanistan",
    "AX": "Aland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua And Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia And Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Democratic Republic",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D\"Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island & Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Islamic Republic Of",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle Of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KR": "Korea",
    "KP": "North Korea",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People\"s Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States Of",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthelemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts And Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin",
    "PM": "Saint Pierre And Miquelon",
    "VC": "Saint Vincent And Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome And Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia And Sandwich Isl.",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard And Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad And Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks And Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis And Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
};

const countryCodes = [{}];
for(const key in countryCodesObj) {
    countryCodes.push({value:key, text:countryCodesObj[key]});
}

const signUpFields = ['firstName', 'lastName', 'company', {key:'position', label:'Job Title'}, {key:'country', label:'Country', type:'select', opts:countryCodes}, {key:'distributor', label:'Preferred/Usual Distributor'}];
const clients = {
    'lakeland': {name: 'Lakeland Permasure CMS', id:'5342fje0pp6dg8g20an2iu03ii', ref:'lakeland', oldId: 15, signUpFields, appTitle:'Lakeland Permasure App', windowTitle:'Lakeland'},
    'respirex': {name: 'Respirex Permasure CMS', id:'5shkp7dpi51momvk9udfrhksjj', ref:'respirex', oldId: 14, signUpFields, appTitle:'Respirex Permasure App', windowTitle:'Respirex'},
    'itp': {name: 'ITP Permasure CMS', id:'bngonk9mbhpd0hbu7aq88625e', ref:'itp', oldId: 11, signUpFields, appTitle:'ITP Permasure App', windowTitle:'ITP'},
};

const clientsById = {};
for(const ref in clients) {
    const client = clients[ref];
    clientsById[client.id] = client;
}

const getAmplifyAuthConfig = (clientRef) => {
    // let userPoolWebClientId = 'bngonk9mbhpd0hbu7aq88625e';
    let userPoolWebClientId = '5shkp7dpi51momvk9udfrhksjj';
    if(clientRef) {
        const client = clients[clientRef];
        if(client) {
            userPoolWebClientId = client.id;
            // setStoredClientId(client.id);
        }
    }

    return {
        identityPoolId: 'eu-west-2:2aaffd99-711b-4463-a316-7e482595f65d',
        region: 'eu-west-2', 
        userPoolId: 'eu-west-2_RSYMBpAHK', 
        userPoolWebClientId,
    };
}

const getAmplifyApiConfig = () => {
    return {
        endpoints: [
            { 
                name:'api', 
                endpoint:'https://ioe2twvd0g.execute-api.eu-west-2.amazonaws.com', 
                custom_header: async () => { return { Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`}; }, 
            }
        ]
    };
}

const getComponents = ({isAdmin, isManager}) => {
    const components = [
        {to: getRouterPath(), title:'Dashboard'},
        {to: getRouterPath('/products'), title:'Products'},
        {to: getRouterPath('/users'), title:'Users'},
        {to: getRouterPath('/content'), title:'Content'},
        // {to: getRouterPath('/lists'), title:'Lists'},
    ];

    if(isAdmin) {
        components.push({to: getRouterPath('/chemicals'), title:'Chemicals'},);
        components.push({to: getRouterPath('/labels'), title:'Labels'},);
        components.push({to: getRouterPath('/hazards'), title:'Hazards'},);  
        components.push({to: getRouterPath('/platform'), title:'Platform'},);  
        components.push({to: getRouterPath('/tags'), title:'Tags'},);  
    }

    components.push({to: '/signout', title:'Sign Out'},);
    
    return components;
}

const languages = [
    {key:'en', label:'English'},
    {key:'us', label:'US'},
    {key:'fr', label:'French'},
    {key:'es', label:'Spanish'},
    {key:'it', label:'Italian'},
    {key:'de', label:'German'},
    {key:'nl', label:'Dutch'},
    {key:'pt', label:'Portuguese'}, 
    {key:'ru', label:'Russian'}, 
    {key:'zh', label:'Chinese'}, 
    {key:'ja', label:'Japanese'}, 
    {key:'pl', label:'Polish'}, 
];

const getProductProtectionFactor = (product) => {
    const rate = utils.toNumber(product.leakageRate);
    return Number(rate !== 0 ? 100/rate : 0).toFixed(3);
}

const getProductLeakgeRate = (product) => {
    const rate = utils.toNumber(product.protectionFactor);
    return Number(rate !== 0 ? 100/rate : 0).toFixed(2);
}

const getStoredClientId = () => {
    return utils.getItem('CLIENT_ID');
}

const setStoredClientId = (clientId) => {
    utils.setItem('CLIENT_ID', clientId);
}

const chemicalPropKeys = {
    'B#': 'b',
    'C#': 'c',
    'F#': 'f',
    'H#': 'h',
    'I#': 'i',
    'N#': 'n',
    'O#': 'o',
    'P#': 'p',
    'Pc': 'pc',
    'RD': 'rd',
    'RI': 'ri',
    'S#': 's',
    'Tc': 'tc',
    'Vc': 'vc',
    'Zc': 'zc',
    'BPt': 'bpt',
    'Br#': 'br',
    'Cl#': 'cl',
    'MCI': 'mci',
    'MPt': 'mpt',
    'MWt': 'mwt',
    'RER': 'rer',
    'Si#': 'si',
    'Sym': 'sym',
    'δD': 'dd',
    'δH': 'dh',
    'δP': 'dp',
    'AntA': 'anta',
    'AntB': 'antb',
    'AntC': 'antc',
    'Area': 'area',
    'LogS': 'logs',
    'MVol': 'mvol',
    'ΔCp': 'dcp',
    'Abr-a': 'abra',
    'Abr-b': 'abrb',
    'Ant1T': 'ant1t',
    'Cond.': 'cond',
    'EdmiW': 'edmiw',
    'Henry': 'henry',
    'MaxPc': 'maxpc',
    'MinMc': 'minmc',
    'FGList': 'fglist',
    'fgroups': 'fgroups',
    'LogKow': 'logkow',
    'LogOHR': 'logohr',
    'SMILES': 'smiles',
    'log η': 'logn',
    'ΔHfus': 'dhfus',
    'δHAcc': 'dhacc',
    'δHDon': 'dhdon',
    'Density': 'density',
    'Formula': 'formula',
    'Ovality': 'ovality',
    'SurfTen': 'surften',
    'Trouton': 'trouton',
    'ΔGform': 'dgform',
    'ΔHcomb': 'dhcomb',
    'ΔHform': 'dhform',
    'Parachor': 'parachpr',
    'ΔHv@BPt': 'dhvbpt',
    '#Heavy Atom': 'heavyatom',
};

const events = {
    CHANNEL: 'ITP',
    types: {
        INIT: 'INIT',
        SIGN_IN: 'SIGN_IN',
        SIGN_OUT: 'SIGN_OUT',
    },
};

const itp = {
    languages,
    getRouterPath,
    getAmplifyAuthConfig,
    getAmplifyApiConfig,
    settings,
    clients,
    clientsById,
    getComponents,
    getProductProtectionFactor,
    getProductLeakgeRate,
    chemicalPropKeys,
    events,
    getStoredClientId,
    setStoredClientId,
};

export default itp;