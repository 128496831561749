import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import store from './core/store';
import { Provider } from 'react-redux';

import Controller from './views/itp/ItpController';
// import Controller from './views/itp-embedded/Controller';

const root = ReactDOM.createRoot(document.getElementById('permasure-signup') || document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <Controller />
      </BrowserRouter>
    </Provider>
  </>
);


