import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Card } from 'react-bootstrap';
import { API } from 'aws-amplify';

import { ItpList, ItpEdit } from '../../comps/ItpData';
import TabsPanel from '../../comps/TabsPanel';
import Btn from '../../comps/Btn';
import Input from '../../comps/Input';
import Table from '../../comps/Table';
import Icon from '../../comps/Icon';

import useMe from '../../hooks/useMe';
import useToast from '../../hooks/useToast';

import utils from '../../core/utils';
import itp from '../../core/itp';

import dayjs from 'dayjs';

const languageFields = utils.getFields(itp.languages);
const categoryOpts = [
    {value:'', text:''},
    {value:'glove', text:'Glove'},
    {value:'garment', text:'Garment'},
];

export const Product = () => {

    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isAdmin, getClient } = useMe();
    const [working, setWorking] = useState(false);
    const [product, setProduct] = useState({name:''});
    const [tags, setTags] = useState({});
    const [tagIds, setTagIds] = useState([]);
    const [fabrics, setFabrics] = useState([]);
    const { addToast } = useToast();
    const { productId } = useParams();
    const client = getClient();
    // const clientId = searchParams.get('clientId');
    const clientId = client.id;
    const isAdd = productId === '0';
    // {key:'url', label:'Url', info:'Linked Product page from App'}
    const editFields = utils.getFields([{key:'fabricId', label:'Fabic', type:'select', opts:fabrics}, 
        'name', 
        {key:'category', label:'Category', type:'select', opts:categoryOpts},
        {key:'manufacturer', label:'Manufacturer'},
        'protectionFactor', 
        {key:'sequence', label:'Sequence', info:'List display sequence number (ascending)'}, 
        {key:'airChanges', label:'Air Changes /hr (l/min)', info:'Number of Air Changes per hour, applicable to Air Fed Products only'}, 
        // {key:'weight', label:'Weight (kg)'},
        {key:'volume', label:'Volume (l)'},
        // {key:'thumbnail', label:'Thumbnail', info:'Currently populated from App, no need for a value in CMS'},
    ]);

    
    
    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Product`);
        if(!isAdd) {
            await loadProduct();
        }
    }

    const loadProduct = async() => {
        setWorking(true);

        try {
            const product = await API.get('api', `/products/${productId}?clientId=${clientId}`);
            
            const dateKeys = ['createdAt', 'updatedAt'];
            for(const dateKey of dateKeys) {
                const date = dayjs(product[dateKey]);
                product[dateKey+'Text'] = date.format(itp.settings.dateTimeFormat)+' ('+date.fromNow()+')';
            }
            product.clientName = itp.clientsById[product.clientId]?.name;
            product.leakageRate = Number(product.leakageRate).toFixed(2);
            product.airChanges = Number(product.airChanges);
            product.leakageRateText = `${product.leakageRate}%`;
            
            setProduct(product);
            setTagIds(utils.toArray(product.tagIds));
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const setProductValue = (key, value) => {
        setProduct({...product, [key]:value});
    }

    const loadTags = async() => {
        if(!product?.clientId && !isAdd) {
            return;
        }
        setWorking(true);

        try {
            const data = await API.get('api', `/tags?clientId=${clientId}`);
            const tags = {};
            for(const t of data) {
                const { type } = t;
                if(!tags[type]) {
                    tags[type] = [];
                }
                tags[type].push(t);
            }
            setTags(tags);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const loadFabrics = async() => {
        if(!product?.clientId && !isAdd) {
            return;
        }
        setWorking(true);

        try {
            const fabrics = await API.get('api', `/fabrics?clientId=${clientId}`);
            const opts = [];
            for(const f of fabrics) {
                opts.push({value:f.id, text:f.name});
            }

            setFabrics(opts);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/products`));
    }

    const onSave = async() => {
        setWorking(true);
        
        product.tagIds = tagIds;

        if(isAdd) {
            try {
                const newProduct = await API.post('api', `/products?clientId=${clientId}`, {body:product});
                navigate(itp.getRouterPath(`/products/${newProduct.id}`));
                addToast(`Saved Product`);
            }
            catch(error) {
                // console.dir(error);
                addToast(error?.error || error, {type:'error'});
            }
        }
        else {
            try {
                await API.put('api', `/products/${productId}`, {body:product});
                addToast(`Saved Product`);
            }
            catch(error) {
                addToast(error, {type:'error'});
            }
        }

        setWorking(false);
    }

    const onRemove = async() => {
        if(!window.confirm('Remove Product?')) { return; }
        setWorking(true);
        try {
            await API.del('api', `/products/${productId}?clientId=${clientId}`);
            navigate(itp.getRouterPath(`/products`));
            addToast(`Deleted Product`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }
        setWorking(false);
    }

    const handleTagId = (tagId, checked) => {
        if(checked) {
            if(!tagIds.includes(tagId)) {
                const copy = utils.copyObject(tagIds);
                copy.push(tagId);
                setTagIds(copy);
            }
        }
        else {
            if(tagIds.includes(tagId)) {
                const copy = utils.copyObject(tagIds);
                const index = copy.findIndex(id=>id===tagId);
                copy.splice(index, 1);
                setTagIds(copy);
            }
        }
    }

    const getTagName = (tag) => {
        return isAdmin() ? (<>{tag?.name} <small><b>{tag?.id}</b></small></>) : tag?.name;
    }

    useEffect(()=>{ init(); }, []);
    useEffect(()=>{ loadFabrics(); loadTags(); }, [product.id]);

    const tabs = [
        {
            title:`Product Details - ${product.name}`,
            iconName: 'UniversalAccess',
            component:(<Input.Fields fields={editFields} data={product} onChange={(key, value)=>{ setProductValue(key, value); }} />),
        },
        {
            title:'Languages',
            iconName: 'Translate',
            component:(<Input.Fields fields={languageFields} data={product} onChange={(key, value)=>{ setProductValue(key, value); }} />),
        },
        {
            title:'Tags',
            iconName: 'Tags',
            component:(<Tabs defaultActiveKey={0}>{Object.keys(tags).map((type, i)=>(
            <Tab key={i} eventKey={i} title={utils.textToTitle(type)}>
                {tags[type].map((tag, i)=>(<Input.Checkbox key={i} checked={tagIds.includes(tag.id)} label={getTagName(tag)} onChange={(checked)=>{ handleTagId(tag.id, checked); }} />))}
            </Tab>))}</Tabs>), 
        },
    ];

    const header = (
        <>
            {/* <Btn text="Back to Products" iconName={'back'} onClick={onBack} />{' '}
            <Btn text="Save" iconName={'save'} onClick={onSave} variant={'primary'} /> */}
        </>
    );

    const classes = [];
    if(working) {
        classes.push('working');
    }

    return (
        <div className={classes.join(' ')}>
            <Row>
            <Col sm="8">
                <TabsPanel tabs={tabs} header={header} />
            </Col>
            <Col sm="4">
                <Card>
                <Card.Header>
                    <div className='float-end'>
                        <Btn text="Back to Products" iconName={'back'} onClick={onBack} />{' '}
                        <Btn text="Remove" iconName={'close'} onClick={onRemove} variant={'danger'} />{' '}
                        <Btn text="Save" iconName={'save'} onClick={onSave} variant={'success'} />
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* <div align="center">
                        <Icon name="UniversalAccess" size={200} />
                    </div>
                    <hr /> */}
                    <table className='table'>
                        <tbody>
                            {/* <tr>
                                <td>Client Name</td>
                                <td>{product?.clientName}</td>
                            </tr> */}
                            <tr>
                                <td>Leakage Rate</td>
                                <td>{itp.getProductLeakgeRate(product)}%</td>
                            </tr>
                            <tr>
                                <td>Created At</td>
                                <td>{product.createdAtText}</td>
                            </tr>
                            <tr>
                                <td>Updated At</td>
                                <td>{product.updatedAtText}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card.Body>
                </Card>
            </Col>
            </Row>
        </div>
    );
}


const listFields = utils.getFields([
    'name', 'fabricName', 'protectionFactor', 
    {key:'leakageRateText', label:'Leakage Rate'},
    {key:'tagFeatureNamesText', label:'Features'},
    'sequence',
]);

export const Products = () => {

    const navigate = useNavigate();
    const { getClient, isAdmin, } = useMe();
    const client = getClient();
    const [products, setProducts] = useState([]);
    const [working, setWorking] = useState();
    const { addToast } = useToast();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Products`);
        await loadProducts();
    }

    const loadProducts = async() => {
        setWorking(true);

        let products = [];
        try {
            products = await API.get('api', `/products?clientId=${client.id}`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }
        products.sort((a,b)=>(a.sequence>b.sequence?1:-1));
        for(const p of products) {
            if(p.fabric) {
                p.fabricName = p.fabric?.name;
            }
            p.protectionFactor = Number(p.protectionFactor).toFixed(1);
            p.leakageRate = Number(p.leakageRate).toFixed(2);
            p.leakageRateText = `${p.leakageRate}%`;
            p.keywords = Object.values(p).join(' ').toLowerCase();
            p.tagFeatureNamesText = p.tagFeatureNames.join(', ');
        }

        setProducts(products);

        setWorking(false);
    }

    const onAction = (product) => {
        navigate(itp.getRouterPath(`/products/${product.id}`));
    }

    const onAdd = () => {
        navigate(itp.getRouterPath(`/products/0`));
    }

    useEffect(()=>{ init(); }, []);

    const classes = [];
    if(working) {
        classes.push('working');
    }

    const tabs = [
        {
            title: `Products (${products.length})`, 
            iconName: 'CardList', 
            component: <Table fields={listFields} data={utils.toArray(products)} onAction={(product)=>{ onAction(product); }} actionText="Edit" placeholder={`Filter Products`} download={true} header={<Btn text="Reload" iconName={'reload'} onClick={loadProducts} />} />
        }
    ];

    const addBtn = (<Btn text="Add Product" onClick={onAdd} />);
    const header = isAdmin() ? (<><Btn text="Manage Fabrics" onClick={()=>{ navigate(itp.getRouterPath('/fabrics')); }} />&nbsp;{addBtn}</>) : addBtn; 

    return (
        <div className={classes.join(' ')}>
            <TabsPanel tabs={tabs} header={header} />
        </div>
    );

}